import React, { useMemo } from "react"
import { BuyButton } from "../BuyButton/BuyButton"

import Skeleton from "react-loading-skeleton"

import "react-loading-skeleton/dist/skeleton.css"
import Artist from "./Artist"
import AuctionPrice from "./AuctionPrice"
import AuctionDate from "./AuctionDate"

import * as styles from "./AuctionDetails.module.scss"
interface IAuctionDetails {
  auction: any
  data: any
  nftType: "auction" | "buyNow"
}

const AuctionDetails = ({ auction, data, nftType }: IAuctionDetails) => {
  const cost = useMemo(() => {
    if (data && auction) {
      if (nftType === "auction") {
        const auctionCost = auction?.currentBidInETH

        const quantity = `${auction?.quantity}/${auction?.nft?.quantity}`

        return <AuctionPrice cost={auctionCost} quantity={quantity} />
      } else if (nftType === "buyNow") {
        const tokenId = data?.asset?.tokenId

        const publicMintToken = auction?.tokens?.find(
          token => token.tokenId === tokenId
        )

        const quantity = `1/${publicMintToken?.quantity}`

        return <AuctionPrice cost={publicMintToken?.cost} quantity={quantity} />
      }
    }
  }, [data, auction, nftType])

  return (
    <div className={styles.auctionDetails}>
      {cost}

      <AuctionDate auction={auction} data={data} nftType={nftType} />

      <div className={styles.auctionDetailsBuyBtn}>
        {auction ? (
          <BuyButton data={data} auction={auction} nftType={nftType} />
        ) : nftType === "buyNow" ? (
          <>
            <span>Available soon for purchase </span>
          </>
        ) : (
          <>
            <Skeleton width="100%" height="35px" />
          </>
        )}
      </div>

      <div className={styles.auctionDetailsTitle}>
        <p>{data?.asset?.title}</p>
      </div>

      <Artist artist={data?.asset?.artist} />

      <div className={styles.auctionMedium}>
        <p>{data?.asset?.description?.description}</p>
      </div>

      {/* <div className={styles.auctionDetailsCurrentBid}>
        <div className={styles.auctionDetailsLabel}>
          <span>
            {auction?.hasEnded
              ? "Winning Bid"
              : auction?.bids?.length > 0
              ? "Current Bid"
              : "Starting Bid"}
          </span>
          {auction ? (
            <p>
              {auction?.bids?.length === 1
                ? "1 bid"
                : `${auction?.bids?.length} bids`}
            </p>
          ) : (
            <Skeleton width="50px" />
          )}
        </div>
        <div className={styles.auctionDetailsCurrentBidInner}>
          <div>
            {auction ? (
              `${auction?.currentBidInETH.toFixed(2)} ETH`
            ) : (
              <Skeleton />
            )}
          </div>
          <p>
            {auction ? (
              `$USD ${auction?.currentBidInUSD.toFixed(2)}`
            ) : (
              <Skeleton />
            )}
          </p>
        </div>
      </div> */}

      {/* <AuctionHistory auctionHistory={auction?.history} />

      <div className={styles.auctionDetailsInfo}>
        <p>
          By placing your bid you agree to Artsy's{" "}
          <a
            href="https://www.artsy.net/conditions-of-sale"
            target="_blank"
            rel="noreferrer"
          >
            Conditions of Sale
          </a>
          .
        </p>
        <p>
          Have a question? Read our{" "}
          <a
            href="https://support.artsy.net/hc/en-us/articles/4420206067479-NFT-Auction-FAQs"
            target="_blank"
            rel="noreferrer"
          >
            auction FAQs
          </a>{" "}
          or <a href="mailto: specialist@artsy.net">ask a specialist</a>.
        </p>
      </div> */}
    </div>
  )
}

export default AuctionDetails

import { Link } from "gatsby"
import React from "react"
import { AiOutlineRight } from "react-icons/ai"
import * as styles from "./ListTile.module.scss"

interface ListTileProps {
  url: string
  title: string
  onClick?: () => void
}

export const ListTile = ({ url, title , onClick}: ListTileProps) => {
  return (
    <Link to={url} className={styles.listTileLink} onClick={onClick}>
      <div className={styles.listTile}>
        <span>{title}</span>
        <AiOutlineRight color="white" />
      </div>
    </Link>
  )
}

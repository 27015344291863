import React from "react"
import Seo from "../../components/seo"
import Page from "../../components/Page/Page"
import { FAQComponent } from "../../components/FAQ/FAQ"
import { graphql, useStaticQuery } from "gatsby"

const FAQ = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaqPage {
        edges {
          node {
            faq {
              id
              question
              answer {
                answer
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Page>
      <Seo title="FAQ" />
      <FAQComponent data={data?.allContentfulFaqPage?.edges?.[0]?.node?.faq} />
    </Page>
  )
}

export default FAQ

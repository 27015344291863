import React, { useState, useMemo, useEffect } from "react"

import differenceInSeconds from "date-fns/differenceInSeconds"
import { utcToZonedTime } from "date-fns-tz"
import cn from "classnames"

import * as styles from "./Countdown.module.scss"

interface CountDownProps {
  deadline: any
  classname: string
  // timerHasEnd?: () => void
}

const Countdown = ({ deadline, classname }: CountDownProps) => {
  const ONE_DAY = 60 * 60 * 24
  const ONE_HOUR = 60 * 60
  const ONE_MINUTE = 60
  const [currentTime, setCurrentTime] = useState(new Date())
  const diffInSeconds = differenceInSeconds(deadline, currentTime)

  const getCoundown = () => {
    if (diffInSeconds <= 1) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      }
    }
    const days = Math.floor(diffInSeconds / ONE_DAY)
    const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR)
    const minutes = Math.floor(
      (diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE
    )
    const seconds =
      diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE

    return {
      days,
      hours,
      minutes,
      seconds,
    }
  }

  const countdown = useMemo(getCoundown, [currentTime])

  useEffect(() => {
    setInterval(() => {
      const now = new Date()
      setCurrentTime(now)
    }, 1000)

    return () => {
      setCurrentTime(new Date())
    }
  }, [])

  return (
    <div className={cn(styles.countdown, classname)}>
      {countdown.days === 0 &&
      countdown.hours === 0 &&
      countdown.minutes === 0 &&
      countdown.seconds === 0 ? null : (
        <>
          <span>{countdown.days}d</span>
          <span>{countdown.hours}h</span>
          <span>{countdown.minutes}m</span>
          <span>{countdown.seconds}s</span>
        </>
      )}
    </div>
  )
}

export default Countdown

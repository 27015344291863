import React from "react"
import * as styles from "./ArtsyFooter.module.scss"
import MetadynLogoWhite from "../../images/metadyn-logo-white.png"
import FooterAllRights from "../FooterAllRights/FooterAllRights"
import { ConnectWalletButton } from "../Header/ConnectWalletButton"
import { Link } from "gatsby"

export const ArtsyFooter = () => {
  return (
    <footer className={styles.footer}>
      <div>
        <div className={styles.footerPoweredBy}>
          <h1>Powered by</h1>
          <a href="https://metadyn.com/">
            <img alt="Metadyn" src={MetadynLogoWhite}></img>
          </a>
        </div>
        <div className={styles.footerItem}>
          <Link to="/faq">
            <h5>FAQ</h5>
          </Link>
        </div>
        <div className={styles.footerItem}>
          <h5>
            <ConnectWalletButton />
          </h5>
        </div>
        <div className={styles.footerItem}>
          <FooterAllRights hideOnMobile />
        </div>
      </div>
    </footer>
  )
}

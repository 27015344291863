import { atom } from "recoil"

export enum AuthState {
  unauthenticated,
  signingIn,
  signingUp,
  authenticated,
}

export const isAuthenticatedState = atom<AuthState>({
  key: "isAuthenticatedState",
  default: AuthState.unauthenticated,
})

export const userState = atom({
  key: "userState",
  default: null,
})

// extracted by mini-css-extract-plugin
export var NFTGridNoResult = "NftMarketplace-module--NFTGridNoResult--V0JKg";
export var nftAuctionGrid = "NftMarketplace-module--nftAuctionGrid--Ar8jM";
export var nftBuyNowGrid = "NftMarketplace-module--nftBuyNowGrid--rn8iK";
export var nftDesktop = "NftMarketplace-module--nftDesktop--vnTWb";
export var nftFilterContainer = "NftMarketplace-module--nftFilterContainer--BVyDe";
export var nftGrid = "NftMarketplace-module--nftGrid--hQaoS";
export var nftMarketplace = "NftMarketplace-module--nftMarketplace--swyL6";
export var nftMarketplaceActiveSwitch = "NftMarketplace-module--nftMarketplaceActiveSwitch--7dFFf";
export var nftMarketplaceMenu = "NftMarketplace-module--nftMarketplaceMenu--X9Dsi";
export var nftMarketplaceSwitch = "NftMarketplace-module--nftMarketplaceSwitch--L5DT7";
export var nftMarketplaceSwitcher = "NftMarketplace-module--nftMarketplaceSwitcher--Cy0HG";
export var nftMarketplaceSwitcherInner = "NftMarketplace-module--nftMarketplaceSwitcherInner--JAT61";
export var nftMobile = "NftMarketplace-module--nftMobile--XHvff";
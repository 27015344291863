import React from "react"

import * as styles from "./AuctionDetails.module.scss"

interface IAuctionPrice {
  cost: string
  quantity: string
}

const AuctionPrice = ({ cost, quantity }: IAuctionPrice) => {
  return (
    <div className={styles.auctionPrice}>
      {cost && quantity && (
        <>
          <b>{cost} ETH</b>
          <span>{quantity}</span>
        </>
      )}
    </div>
  )
}

export default AuctionPrice

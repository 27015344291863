import React, { useEffect, useMemo, useRef, useState } from "react"
import { useSpring, animated } from "@react-spring/web"
import * as styles from "./CreatorFilter.module.scss"
import { AiOutlineDown, AiOutlineUp, AiOutlineFilter } from "react-icons/ai"

import { graphql, useStaticQuery } from "gatsby"
import { useQueryParamString } from "react-use-query-param-string"

interface ICreatorFilter {
  name: "buyNow" | "auction"
}

const CreatorFilter = ({ name }: ICreatorFilter) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulArtist {
        edges {
          node {
            name
            id
          }
        }
      }

      allContentfulHomepage {
        edges {
          node {
            id
            smBs {
              __typename
              ... on ContentfulArtist {
                id
              }
            }
          }
        }
      }
    }
  `)

  const [filter, setFilter] = useQueryParamString(`${name}filter`, "")
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState("All Creators")
  const ref = useRef<HTMLParagraphElement>(null)
  const buttonRef = useRef(null)
  const [animatedStyles, animate] = useSpring(() => ({ height: "0px" }), [])

  useEffect(() => {
    if (filter && filter !== "all") {
      setSelected(filter?.split(" ")?.slice(1)?.join(" "))
    } else {
      setSelected("All Creators")
    }
  }, [filter])

  const handleClickOutside = event => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setOpen(false)
    } else {
      setOpen(!open)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    animate({
      height: (open ? ref?.current?.offsetHeight : 0) + "px",
    })
    return () => document.removeEventListener("click", handleClickOutside, true)
  }, [animate, ref, open])

  const creators = data?.allContentfulArtist?.edges

  creators.sort((a, b) => {
    if (a?.node?.name) {
      const name = a?.node?.name.toString().toLowerCase()
      if (name.includes("harris")) return -3
      if (name.includes("serge")) return -2
      if (name.includes("mayowa")) return -1
      if (name.includes("denitia")) return 1
      else return 2
    } else {
      return 2
    }
  })

  return (
    <div className={styles.creatorFilter}>
      <div className={styles.creatorFilterContainer}>
        <div ref={buttonRef} className={styles.creatorFilterButton}>
          <button>
            {selected}
            {open && <AiOutlineUp />}
            {!open && <AiOutlineDown />}
          </button>
        </div>

        <animated.div style={animatedStyles}>
          <div ref={ref}>
            <ul className={styles.creatorFilterList}>
              <li className={styles.creatorFilterListItem}>
                <button
                  onClick={() => {
                    setFilter("all")
                    setSelected("All Creators")
                  }}
                >
                  All Creators
                </button>
              </li>

              <li className={styles.creatorFilterListItem}>
                <ul className={styles.creatorFilterInnerList}>
                  {creators?.map(edge => (
                    <li key={edge.node.id}>
                      <button
                        onClick={() => {
                          setFilter(`Creator ${edge?.node?.name}`)
                          setSelected(edge?.node?.name)
                        }}
                      >
                        {edge?.node?.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
        </animated.div>
      </div>
    </div>
  )
}

export default CreatorFilter

import { find } from "lodash"

export const enricheLotWithPublicMints = (lots: any, publicMints: any) => {
  return lots?.map((lot: any) => {
    return {
      ...(lot.node ? lot.node : lot),
      auction: find(publicMints, a => {
        return a.id === (lot.publicMintId || lot.node.publicMintId)
      }),
    }
  })
}

export const getPublicMintIds = (lots: any) => {
  return lots?.map((lot: any) => {
    return lot?.publicMintId || lot?.node?.publicMintId
  })
}

export interface AssociateWalletWithUserRequestData {
  wallet: string
  name: string
  email: string
  partner: string
  partnerID: string
  eventType: string
}

export const associateWalletWithUser = async (
  requestData: AssociateWalletWithUserRequestData
) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(requestData),
  }
  await fetch("/api/wallets/events", requestOptions)
}

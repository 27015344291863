import React, { useMemo } from "react"

import { Link } from "gatsby"
import ethLogo from "../../images/ethereum-1.svg"

import EllipsisText from "react-ellipsis-text"

import * as styles from "./NftCard.module.scss"
import { isFuture } from "date-fns"

interface INftCard {
  nft: any
  nftType: "auction" | "buyNow"
}

const NftCard = ({ nft, nftType }: INftCard) => {
  const creatorTitle = nft?.asset?.title.split(" x ")

  const isGrayedOut = useMemo(() => {
    if (nftType === "buyNow") {
      if (
        !nft?.asset?.tokenId ||
        !nft?.auction ||
        !nft?.auction?.tokens?.find(obj => {
          return obj?.tokenId === nft?.asset?.tokenId
        }) ||
        nft?.auction?.sold.indexOf(nft?.asset?.tokenId) !== -1 ||
        isFuture(new Date(nft?.auction?.start * 1000))
      ) {
        return true
      }
    }
  }, [nft, nftType])

  const { cost, quantity }: any = useMemo(() => {
    if (nft) {
      if (nftType === "auction") {
        const auctionCost = nft.auction?.currentBidInETH

        const quantity = `${nft?.auction?.quantity}/${nft?.auction?.nft?.quantity}`

        return {
          cost: auctionCost,
          quantity,
        }
      } else if (nftType === "buyNow") {
        const tokenId = nft?.asset?.tokenId

        const publicMintToken = nft?.auction?.tokens?.find(
          token => token.tokenId === tokenId
        )

        const quantity = `1/${publicMintToken?.quantity}`

        return {
          cost: publicMintToken?.cost,
          quantity,
        }
      }
    }
  }, [nft, nftType])

  return (
    <Link
      to={`/marketplace/${nftType}/${nft?.asset?.slug}/${nft?.lotId}`}
      className={styles.nftCardLink}
      style={{
        opacity: isGrayedOut ? 0.4 : 1,
      }}
    >
      <div className={styles.nftCard}>
        {nft?.asset?.mainMedia?.file?.contentType?.includes("video") ? (
          <video
            className={styles.nftCardBackgroundImg}
            src={nft?.asset?.mainMedia?.file?.url}
            width="100%"
            height="100%"
            playsInline
            autoPlay
            muted={true}
            loop
          ></video>
        ) : nft?.asset?.mainMedia?.file?.contentType?.includes("audio") ? (
          <>
            <img
              className={styles.nftCardBackgroundImg}
              src={nft?.asset?.animationPreviewMedia?.file?.url}
              alt={nft?.asset?.animationPreviewMedia?.title}
            />
            <audio controls>
              <source
                src={nft?.asset?.mainMedia?.file?.url}
                type="audio/mpeg"
              />
              <source src={nft?.asset?.mainMedia?.file?.url} type="audio/ogg" />
              Your browser does not support the audio element.
            </audio>
          </>
        ) : (
          <img
            className={styles.nftCardBackgroundImg}
            src={nft?.asset?.mainMedia?.file?.url}
            alt={nft?.asset?.mainMedia?.title}
          />
        )}

        <span className={styles.nftCardId}>{nft?.lotId}</span>
        {/* <img
          className={styles.nftCardForgroundImg}
          src={nft?.asset?.mainMedia?.file?.url}
          alt={nft?.asset?.mainMedia?.title}
        /> */}
        {/* TODO replace category*/}
        <div className={styles.nftCardBody}>
          <span className={styles.nftCardCategory}>
            {creatorTitle ? creatorTitle[0] : ""}
          </span>
          {creatorTitle && creatorTitle[1] && (
            <>
              <span className={styles.nftCardCategory}>x</span>
              <span className={styles.nftCardCategory}>{creatorTitle[1]}</span>
            </>
          )}

          {/* <p className={styles.nftCardDescription}>
          {nft?.asset?.description?.description && (
            <EllipsisText
            text={nft?.asset?.description?.description}
            length={50}
            />
            )}
          </p> */}

          {/* <div className={styles.nftCardArtist}>
          <span>{nft?.asset?.artist?.name}</span>
        </div> */}

          <div className={styles.nftCardPrice}>
            <span className={styles.nftCardPriceLabel}>Price</span>
            <p>
              <img src={ethLogo} width="10px" alt="ethereum" />
              {cost}
              {"  "}
              <span>{quantity}</span>
            </p>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default NftCard

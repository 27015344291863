import React from "react"
import { AiOutlineDown } from "react-icons/ai"
import * as styles from "./ScrollToRead.module.scss"

export const ScrollToRead = () => {
  return (
    <div className={styles.ScrollToReadControls}>
      <div>
        <p>scroll to read more</p>
        <AiOutlineDown />
      </div>
    </div>
  )
}

import React, { useEffect, useState } from "react"

import NavBar from "../NavBar/NavBar"

import * as styles from "./Header.module.scss"
import Menu from "../Menu/Menu"
import { AiOutlineClose } from "react-icons/ai"
import { Link } from "gatsby"
import cn from "classnames"
import LOGO_BLACK from "../../images/1stAveMachine_Monograph Black.png"
import LOGO_WHITE from "../../images/1stAveMachine_Monograph White.png"

const Header = ({ location }: any) => {
  const [showMenu, setShowMenu] = useState(false)
  const [activeHeader, setActiveHeader] = useState(false)
  const [width, setWidth] = useState<number>(1024)

  useEffect(() => {
    setWidth(window.innerWidth)
    const isMobile = width <= 769
    const isHomePage =
      location.pathname === "/" || location.pathname === "/faq/"
    window.addEventListener("scroll", handleScroll)
    window.addEventListener("resize", handleWindowSizeChange)
    if (isMobile) {
      if (isHomePage) {
        setActiveHeader(false)
      } else {
        setActiveHeader(true)
      }
    } else {
      setActiveHeader(false)
    }

    function handleWindowSizeChange() {
      setWidth(window.innerWidth)
    }

    function handleScroll() {
      if (isMobile) {
        if (isHomePage) {
          if (window.scrollY > 200) {
            setActiveHeader(true)
          } else {
            setActiveHeader(false)
          }
        }
      }
    }
    return () => {
      window.removeEventListener("scroll", handleScroll)
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [width, location])

  return (
    <header
      className={cn(styles.header, {
        [styles.activeHeader]: activeHeader,
        [styles.showMenu]: showMenu,
      })}
    >
      <div className={styles.headerInner}>
        <div className={styles.headerTopNav}>
          <Link className={styles.headerMainLogo} id="" to="/">
            <img
              src={
                showMenu ? LOGO_WHITE : !activeHeader ? LOGO_WHITE : LOGO_BLACK
              }
              alt="Logo"
            ></img>
          </Link>
          <NavBar />
          <button
            onClick={() => {
              setShowMenu(!showMenu)
            }}
            className={styles.headerMenuToggle}
          >
            {!showMenu && (
              <svg
                style={{ transform: "scale(1.5)" }}
                viewBox="0 0 18 18"
                fill={!activeHeader ? "#fff" : "#000"}
                height="18px"
                width="18px"
                className="Icon-sc-1eovgys-0 kAqktO"
              >
                <title>Menu</title>
                <g fillRule="evenodd">
                  <path d="M3 3h12.026v1H3zM3 8.512h12.026V9.5H3zM3 13.996h12.026V15H3z"></path>
                </g>
              </svg>
            )}
            {showMenu && <AiOutlineClose color="white" />}
          </button>
        </div>
      </div>

      {showMenu && <Menu closeMenu={() => setShowMenu(false)} />}
    </header>
  )
}

export default Header

import React from "react"

import { createPortal } from "react-dom"
import cn from "classnames"

import CloseIcon from "../../images/closeIconWhite.png"

import * as styles from "./Modal.module.scss"

interface IModal {
  className?: string
  children: React.ReactNode
  closeModal: () => void
  mobileFullScreen?: boolean
}

const Modal = ({
  className,
  children,
  closeModal,
  mobileFullScreen = true,
}: IModal) => {
  return createPortal(
    <div
      className={cn(styles.modal, {
        [styles.showOnMobile]: mobileFullScreen,
      })}
    >
      <div
        className={styles.modalClose}
        onClick={closeModal}
        role="presentation"
        aria-label="Close Modal"
      ></div>
      <div className={cn(styles.modalInner, className)}>
        <div className={styles.modalCloseBtn}>
          <button onClick={closeModal}>
            <img src={CloseIcon} width="25px" height="25px" alt="Close"></img>
          </button>
        </div>
        {children}
      </div>
    </div>,
    document.getElementById("___gatsby")!
  )
}

export default Modal

import React, { useMemo, useState } from "react"
import { useQueryParamString } from "react-use-query-param-string"
import CreatorFilter from "../CreatorFilter/CreatorFilter"
import { MobileCreatorFilter } from "../CreatorFilter/MobileCreatorFilter"
import * as styles from "./BuyNowSection.module.scss"
import { NFTCard } from "./NFTCard/NFTCard"
import { SectionHeader } from "./SectionHeader/SectionHeader"
import loadable from "@loadable/component"

import {
  enricheLotWithPublicMints,
  getPublicMintIds,
} from "../../utils/publicMint"
import { isFuture } from "date-fns"
import { NoResultText } from "../NoResultText/NoResultText"
import { concat, groupBy } from "lodash"

const LoadableGalleryMonitorPublicMint = loadable(
  () =>
    import("../../components/GalleryMonitorPublicMint/GalleryMonitorPublicMint")
)

export const BuyNowSection = ({ data }: any) => {
  const [filter] = useQueryParamString(`buyNowfilter`, "")
  const [hasData, setHasdata] = useState(true)

  const dataFilterd = useMemo(() => {
    const filterName = filter.split(" ")[0]
    const result = filter.split(" ").slice(1).join(" ")

    switch (filterName) {
      case "":
        return data

      case "all":
        return data

      case "Creator":
        return data.filter(
          buyNow => buyNow?.node?.asset?.artist?.name === result
        )

      case "SMB":
        return data.filter(buyNow => buyNow?.node?.asset?.smb?.name === result)

      default:
        return data
    }
  }, [filter, data])

  const publicMints = getPublicMintIds(data)

  return (
    <div className={styles.buyNowSection}>
      <div className={styles.BuyNowSectionallContainer}>
        <div className={styles.buyNowSectionHeader}>
          <SectionHeader subTitle="Buy Now" />
          <CreatorFilter name="buyNow" />
          <MobileCreatorFilter name="buyNow" />
        </div>

        <div
          className={styles.BuyNowSectioncardsContainer}
          style={{ display: hasData ? "grid" : "flex" }}
        >
          <LoadableGalleryMonitorPublicMint
            publicMints={publicMints}
            render={({ result }: any) => {
              const enrichedData = enricheLotWithPublicMints(
                data || [],
                result?.publicMints
              )

              const filteredEnrichedData = enrichedData.filter(data =>
                dataFilterd.find(nft => nft.node.id === data.id)
              )

              //Sort order: 1. available 2. coming soon 3. sold out
              // const sorted = groupBy(filteredEnrichedData, (item: any) => {
              //   const isComingSoon =
              //     !item?.asset?.tokenId ||
              //     !item?.auction ||
              //     !item?.auction?.tokens?.find(obj => {
              //       return obj?.tokenId === item?.asset?.tokenId
              //     }) ||
              //     isFuture(new Date(item?.auction?.start * 1000))

              //   const isSold = !isComingSoon
              //     ? item?.auction?.sold?.indexOf(item?.asset?.tokenId) !== -1
              //     : false

              //   return isComingSoon
              //     ? "comingSoon"
              //     : isSold
              //     ? "sold"
              //     : "available"
              // })

              // const cards = concat(
              //   sorted.available,
              //   sorted.comingSoon,
              //   sorted.sold
              // ).filter(Boolean)

              if (filteredEnrichedData.length <= 0) {
                setHasdata(false)
                return <NoResultText />
              } else {
                setHasdata(true)
                return (
                  <>
                    {filteredEnrichedData?.map((edge: any) => {
                      const tokenId = edge?.asset?.tokenId

                      const publicMintToken = edge?.auction?.tokens?.find(
                        token => token.tokenId === tokenId
                      )

                      const cost = publicMintToken?.cost

                      return (
                        <NFTCard
                          key={edge?.id}
                          slug={edge?.asset?.slug}
                          lotId={edge?.lotId}
                          imageURL={edge?.asset?.mainMedia}
                          title={"#" + edge?.lotId}
                          //TODO
                          price={cost || edge?.cost}
                          nftType="buyNow"
                          tokenId={edge?.asset?.tokenId}
                          auction={edge?.auction}
                          animationPreviewMedia={
                            edge?.asset?.animationPreviewMedia
                          }
                        />
                      )
                    })}
                  </>
                )
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

import { AuctionStatus, AuctionType } from "@monegraph/graph"
import cx from "classnames"
import { get, includes, split, filter, find } from "lodash"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilState } from "recoil"
import useBuyButton, { BuyButtonState } from "../../hooks/use-buy-button"
import { isAuthenticatedState, userState } from "../../state/user"
import { Action, track } from "../../utils/analytics"
import { isFuture, isPast } from "date-fns"
import Modal from "../Modal/Modal"

import MetamaskLogo from "../../images/metamask.svg"
import WalletConnectLogo from "../../images/walletconnect.svg"

import * as styles from "./BuyButton.module.scss"

interface IBuyButton {
  auction: any
  data: any
  nftType: "auction" | "buyNow"
}

export const BuyButton = ({ data, auction, nftType }: IBuyButton) => {
  const [isLoading, setIsLoading] = useState(false)
  const [amount, setAmount] = useState("")
  const [currentConnectedWalletAddress, setCurrentConnectedWalletAddress] =
    useState("")
  const [user] = useRecoilState(userState)
  const [error, setError] = useState<null | string>()
  const [openConnectModal, setOpenConnectModal] = useState(false)

  const metamaskInstalled = useMemo(() => {
    return typeof window !== "undefined" && window.ethereum
  }, [])

  const {
    buttonState,
    clearErrors,
    errorState,
    wallet,
    onBuy,
    onBuyNow,
    onSettle,
    onConnectMetamask,
    onConnectWalletconnect,
    minimumBid,
  } = useBuyButton(auction, nftType, data)

  const [authState] = useRecoilState(isAuthenticatedState)

  useMemo(() => {
    if (wallet.status === "connected") {
      setOpenConnectModal(false)
    }

    if (wallet.connector === "walletconnect") {
      if (wallet.ethereum) {
        wallet.ethereum.on = null
      }
    }
  }, [wallet.status, wallet.connector, wallet.ethereum])

  const userBids = useMemo(() => {
    const wallets = split(user?.["custom:walletsAddresses"], ",").map(w =>
      w.toLowerCase()
    )

    return filter(auction?.bids, (bid: any) => {
      return includes(wallets, bid.bidder.id)
    })?.length
  }, [auction?.history, wallet?.account, user])

  useEffect(() => {
    setIsLoading(false)
  }, [userBids])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount((event?.target?.value || "").replace(/[^0-9.]/g, ""))
  }

  const onBuyClick = useCallback(async () => {
    setIsLoading(true)

    track(Action.BID, { auctionId: auction?.id })

    try {
      clearErrors()

      try {
        await onBuy(amount ? amount : auction?.minimumBid)
      } catch (error) {
        if (error?.code) {
          switch (error?.code) {
            // case -32000:
            //   setError(
            //     "Not enough funds in your wallet to complete the transaction!"
            //   )
            //   break
            case 4001:
              setIsLoading(false)
              break
            default:
          }
        }
      }

      setIsLoading(false)
    } catch (error) {}
  }, [amount, auction?.id, auction?.minimumBid, clearErrors, onBuy])

  const onBuyNowClick = async () => {
    try {
      clearErrors()

      try {
        await onBuyNow()
      } catch (error) {
        if (error?.code) {
          switch (error?.code) {
            // case -32000:
            //   setError(
            //     "Not enough funds in your wallet to complete the transaction!"
            //   )
            //   break
            case 4001:
              setIsLoading(false)
              break
            default:
          }
        }
      }

      setIsLoading(false)
    } catch (error) {}
  }

  const tokenId = data?.asset?.tokenId
  const tokenIdExistsInContract = auction?.tokens?.find(
    (token: any) => token.tokenId === tokenId
  )

  const buttonProps = useMemo(() => {
    if (auction && nftType === "buyNow") {
      if (
        isFuture(auction.general?.start) ||
        !tokenId ||
        !tokenIdExistsInContract
      ) {
        return { label: "Coming soon", disabled: true }
      }

      const sold = find(auction?.recipients, {
        definition: {
          tokenId: data?.asset?.tokenId,
        },
      })

      if (sold) {
        return { label: "Sold", disabled: true }
      }
    } else if (auction && nftType === "auction") {
      if (isFuture(auction.dateStarted * 1000)) {
        return { label: "Coming soon", disabled: true }
      }
    }

    if (isLoading) {
      return { label: "processing...", disabled: true }
    }

    if (buttonState === BuyButtonState.Biddable) {
      if (wallet.status === "connected") {
        if (currentConnectedWalletAddress !== wallet.account) {
          setCurrentConnectedWalletAddress(wallet.account)
        }

        if (nftType === "auction") {
          return {
            label: "Bid",
            onClick: onBuyClick,
          }
        } else if (nftType === "buyNow") {
          return {
            label: "Buy Now",
            onClick: onBuyNowClick,
          }
        } else {
          return {
            label: "Coming soon",
            disabled: true,
          }
        }
      }
    } else if (buttonState === BuyButtonState.Sold) {
      return { label: "Closed", onClick: () => {}, disabled: true }
    } else {
      return { label: "Closed", onClick: () => {}, disabled: true }
    }

    if (wallet.status !== "connected") {
      return {
        label: "Connect Wallet",
        onClick: () => {
          setOpenConnectModal(true)
        },
      }
    }
  }, [
    auction?.seller?.id,
    authState,
    buttonState,
    isLoading,
    onBuyClick,
    onSettle,
    wallet?.account,
    wallet?.status,
    currentConnectedWalletAddress,
  ])

  const alertProps = useMemo(() => {
    if (errorState) {
      return {
        label: errorState,
        style: {
          color: "rgb(176, 0, 32)",
        },
      }
    }

    const userWallets = split(user?.["custom:walletsAddresses"], ",").map(w =>
      w.toLowerCase()
    )

    if (userWallets.includes(auction?.highestBid?.bidder?.id?.toLowerCase())) {
      return {
        label: "You are currently in the lead.",
        style: {},
        indicator: "success",
      }
    } else if (userBids > 0) {
      return {
        label: "Someone else has taken the lead.",
        style: {},
        indicator: "error",
      }
    }
  }, [auction?.highestBid?.bidder?.id, errorState, user, userBids])

  const inputError = useMemo(() => {
    if (
      auction?.type === AuctionType.TRADITIONAL &&
      !new RegExp(/^\d*\.?\d*$/).test(amount)
    ) {
      return "Invalid bid"
    }
    return null
  }, [amount, auction?.type])

  const bidInput = useMemo(() => {
    return buttonState === BuyButtonState.Biddable ? (
      <>
        <input
          placeholder={`Enter Bid: ${parseFloat(minimumBid).toFixed(
            3
          )} minimum`}
          value={amount}
          onChange={onChange}
          disabled={
            get(auction, "status", AuctionStatus.OPEN) !== AuctionStatus.OPEN
          }
        />
        {inputError ? <span style={{ color: "red" }}>{inputError}</span> : null}
        {error ? <span style={{ color: "red" }}>{error}</span> : null}
      </>
    ) : null
  }, [amount, auction, buttonState, inputError, minimumBid, error])

  const metaMaskButton = useMemo(() => {
    return metamaskInstalled
      ? [
          <button
            key="button"
            onClick={onConnectMetamask}
            className="flex justify-start text-left px-5 py-10 text-center items-center gap-3 hover:bg-slate-100 w-full"
          >
            <img src={MetamaskLogo} width="50px" />
            <span className="text-lg">Metamask</span>
          </button>,
          <hr key="spacer" />,
        ]
      : null
  }, [metamaskInstalled])

  return (
    <div>
      {data.ended !== true &&
      nftType === "auction" &&
      isPast(auction?.dateStarted * 1000) ? (
        <>
          <div className={styles.auctionDetailsBidInput}>{bidInput}</div>
        </>
      ) : null}

      <div className={styles.auctionDetailsButton}>
        {buttonProps && (
          <button
            onClick={buttonProps?.onClick}
            disabled={buttonProps?.disabled || !!inputError}
          >
            {buttonProps?.label}
          </button>
        )}
        {alertProps && (
          <div
            className={cx(
              styles.alertContainer,
              styles[alertProps.indicator || ""]
            )}
            style={alertProps.style}
          >
            {alertProps?.label}
            <div className={styles.indicator}></div>
          </div>
        )}
      </div>

      {openConnectModal && (
        <Modal
          mobileFullScreen={true}
          closeModal={() => setOpenConnectModal(false)}
        >
          <div className={styles.buyButtonConnectModal}>
            {metaMaskButton}

            <button
              onClick={onConnectWalletconnect}
              className="flex justify-start text-left px-5 py-10 text-center items-center gap-3 hover:bg-slate-100 w-full"
            >
              <img src={WalletConnectLogo} width="50px" />
              <span className="text-lg">Wallet Connect</span>
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}

import { isFuture } from "date-fns"
import { Link } from "gatsby"
import React, { useMemo } from "react"
import ethLogo from "../../../images/ethereum-1.svg"
import * as styles from "./NFTCard.module.scss"

interface NFTCardProps {
  lotId: string
  imageURL: any
  title: string
  price: number
  slug: string
  nftType: "auction" | "buyNow"
  tokenId: string
  auction: any
  animationPreviewMedia: any
}

export const NFTCard = ({
  lotId,
  imageURL,
  title,
  price,
  slug,
  nftType,
  tokenId,
  auction,
  animationPreviewMedia,
}: NFTCardProps) => {
  const isGrayedOut = useMemo(() => {
    if (nftType === "buyNow") {
      if (
        !tokenId ||
        !auction ||
        !auction?.tokens?.find(obj => {
          return obj?.tokenId === tokenId
        }) ||
        auction?.sold.indexOf(tokenId) !== -1 ||
        isFuture(new Date(auction?.start * 1000))
      ) {
        return true
      }
    }
  }, [auction, nftType, tokenId])

  return (
    <Link
      to={`/marketplace/${nftType}/${slug}/${lotId}`}
      style={{ position: "relative" }}
    >
      <div
        style={{
          opacity: isGrayedOut ? 0.4 : 1,
        }}
        className={styles.NFTcard}
      >
        {imageURL?.file?.contentType?.includes("video") ? (
          <video
            className={styles.nftCardBackgroundImg}
            src={imageURL?.file?.url}
            width="100%"
            height="100%"
            playsInline
            autoPlay
            muted={true}
            loop
          ></video>
        ) : imageURL?.file?.contentType?.includes("audio") ? (
          <>
            <img
              className={styles.nftCardBackgroundImg}
              src={animationPreviewMedia?.file?.url}
              alt={animationPreviewMedia?.title}
            />
            <audio controls>
              <source src={imageURL?.file?.url} type="audio/mpeg" />
              <source src={imageURL?.file?.url} type="audio/ogg" />
              Your browser does not support the audio element.
            </audio>
          </>
        ) : (
          <img
            className={styles.nftCardBackgroundImg}
            src={imageURL?.file?.url}
            alt={imageURL?.title}
          />
        )}
        <div>
          <div className={styles.NFTCardbottomContainer}>
            <span>{title}</span>
            <div className={styles.NFTCardcircleContainer}>
              <img src={ethLogo} width="10px" alt="ethereum" />
              <span>{parseFloat(price).toFixed(3)}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

import React from "react"
import * as styles from "./AuctionDetails.module.scss"

const Artist = ({ artist }: any) => {
  return (
    <div className={styles.auctionDetailsAuthorBox}>
      <h3>
        Artwork by <b>{artist?.name}</b>
      </h3>
    </div>
  )
}

export default Artist

import React from "react"

import * as styles from "./Menu.module.scss"
import { ConnectWalletButton } from "../Header/ConnectWalletButton"
import { ListTile } from "../ListTile/Listtile"

const Menu = ({ closeMenu }: any) => {
  return (
    <menu className={styles.menu}>
      <ul className={styles.menuList}>
        <li>
          <ListTile url="/" title="Home" onClick={closeMenu} />
        </li>
        <li>
          <ListTile
            url="/nft-marketplace"
            title="NFT Marketplace"
            onClick={closeMenu}
          />
        </li>
        <li>
          <ListTile url="/faq" title="FAQ" onClick={closeMenu} />
        </li>
      </ul>
      <ul className={styles.menuSecondList}>
        <li>
          <ConnectWalletButton />
        </li>
      </ul>
    </menu>
  )
}

export default Menu

import React, { useMemo } from "react"
import { graphql, PageProps } from "gatsby"

import Seo from "../../components/seo"
import AuctionDetails from "../../components/AuctionDetails/AuctionDetails"

import Loadable from "@loadable/component"

import Container from "../../components/Container/Container"

import * as styles from "./Auction.module.scss"
import Page from "../../components/Page/Page"
import Carousel from "../../components/Carousel/Carousel"
import { useMonitorPublicMint } from "../../hooks/use-monitor-public-mint"
import Skeleton from "react-loading-skeleton"

const LoadableAuctionMonitor = Loadable(
  () => import("../../components/AuctionMonitor/AuctionMonitor")
)

const LoadablePublicMintMonitor = Loadable(
  () => import("../../components/PublicMintMonitor/PublicMintMonitor")
)

const LoadableGalleryMonitor = Loadable(
  () => import("../../components/GalleryMonitor/GalleryMonitor")
)

const Auction = ({ data }: PageProps<any>) => {
  const auction = data?.contentfulLot
  const buyNow = data?.contentfulPublicMintLot

  const parsedData = useMemo(
    () => (auction ? auction : buyNow),
    [auction, buyNow]
  )

  const nftType = useMemo(
    () => (auction ? "auction" : "buyNow"),
    [auction, buyNow]
  )

  const mainMedia = parsedData.asset?.mainMedia

  const ogMedia = useMemo(() => {
    if (mainMedia?.file?.contentType?.includes("video")) {
      return { video: `https:${mainMedia?.file?.url}` }
    } else if (mainMedia?.file?.contentType?.includes("audio")) {
      return {
        type: "audio",
        audio: mainMedia?.file?.url,
        backgroundImage: parsedData?.animationPreviewMedia?.file?.url,
      }
    }

    return { image: `https:${mainMedia?.file?.url}` }
  }, [mainMedia])

  return (
    <Page>
      <div className={styles.auctionDetailContainer}>
        <Seo
          title={`${parsedData?.asset?.artist?.name} | ${parsedData?.asset?.title}`}
          description={parsedData?.shortDescription}
          type="website"
          {...ogMedia}
        />

        <div className={styles.auctionGrid}>
          <div className={styles.auctionCarousel}>
            <Carousel
              images={[mainMedia]}
              animationPreviewMedia={parsedData?.asset?.animationPreviewMedia}
            />
          </div>
          {auction ? (
            <LoadableAuctionMonitor
              auctionId={parsedData?.auctionId}
              render={({ data: auction }: any) => {
                return (
                  <AuctionDetails
                    nftType={nftType}
                    auction={auction}
                    data={parsedData}
                  />
                )
              }}
            />
          ) : (
            <LoadablePublicMintMonitor
              publicMintId={parsedData?.publicMintId}
              render={({ data: publicMint }: any) => {
                return (
                  <AuctionDetails
                    nftType={nftType}
                    auction={publicMint}
                    data={parsedData}
                  />
                )
              }}
            />
          )}
        </div>
      </div>
    </Page>
  )
}

export default Auction

export const lotQuery = graphql`
  query ($lotId: String!) {
    contentfulLot(contentful_id: { eq: $lotId }) {
      id
      auctionId
      lotId
      ended
      asset {
        __typename
        ... on ContentfulNftAsset {
          id
          title
          description {
            description
          }
          contractAddress
          artist {
            name
            profilePicture {
              gatsbyImageData
            }
            description {
              description
            }
          }
          mainMedia {
            file {
              contentType
              url
            }
            gatsbyImageData
            title
          }
          animationPreviewMedia {
            file {
              url
              contentType
            }
            gatsbyImageData
            title
          }
          title
        }
      }
    }

    contentfulPublicMintLot(contentful_id: { eq: $lotId }) {
      id
      publicMintId
      lotId
      ended
      cost
      asset {
        __typename
        ... on ContentfulNftAsset {
          id
          title
          description {
            description
          }
          contractAddress
          tokenId
          artist {
            name
            profilePicture {
              gatsbyImageData
            }
            description {
              description
            }
          }
          mainMedia {
            file {
              contentType
              url
            }
            gatsbyImageData
            title
          }
          title
        }
      }
    }
  }
`

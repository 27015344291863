// extracted by mini-css-extract-plugin
export var activeHeader = "Header-module--activeHeader--NsXqo";
export var buyButtonConnectModal = "Header-module--buyButtonConnectModal--T-5W5";
export var buyButtonModal = "Header-module--buyButtonModal--nlOKW";
export var header = "Header-module--header--NXzL8";
export var headerInner = "Header-module--headerInner--sOeu8";
export var headerMainLogo = "Header-module--headerMainLogo--HQOei";
export var headerMenuToggle = "Header-module--headerMenuToggle--VUyAa";
export var headerTopNav = "Header-module--headerTopNav--3FOG1";
export var headerTopNavLinks = "Header-module--headerTopNavLinks--JSggV";
export var logo = "Header-module--logo--7bOtT";
export var mainLogo = "Header-module--mainLogo--qVIem";
export var showMenu = "Header-module--showMenu--myL97";
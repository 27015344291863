import React, { useEffect, useRef, useState, useMemo } from "react"

import { graphql, useStaticQuery } from "gatsby"
import { useQueryParamString } from "react-use-query-param-string"
import loadable from "@loadable/component"
import cn from "classnames"

import { enricheLotWithAuction, getAuctionsIds } from "../../utils/auction"

import Seo from "../../components/seo"
import Container from "../../components/Container/Container"
import Page from "../../components/Page/Page"
import NftCard from "../../components/NftCard/NftCard"
import CreatorFilter from "../../components/CreatorFilter/CreatorFilter"
import { MobileCreatorFilter } from "../../components/CreatorFilter/MobileCreatorFilter"

import * as styles from "./NftMarketplace.module.scss"
import {
  enricheLotWithPublicMints,
  getPublicMintIds,
} from "../../utils/publicMint"
import { isFuture } from "date-fns"
import { NoResultText } from "../../components/NoResultText/NoResultText"
import { concat, groupBy } from "lodash"

const LoadableGalleryMonitor = loadable(
  () => import("../../components/GalleryMonitor/GalleryMonitor")
)

const LoadableGalleryMonitorPublicMint = loadable(
  () =>
    import("../../components/GalleryMonitorPublicMint/GalleryMonitorPublicMint")
)

const NftMarketplace = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLot(filter: { ended: { eq: false } }) {
        edges {
          node {
            id
            lotId
            ended
            auctionId
            asset {
              title
              slug
              description {
                description
              }
              mainMedia {
                file {
                  url
                  contentType
                }
                gatsbyImageData
              }
              animationPreviewMedia {
                file {
                  url
                  contentType
                }
                title
              }

              artist {
                id
                name
                slug
              }
            }
          }
        }
      }

      allContentfulPublicMintLot(filter: { ended: { eq: false } }) {
        edges {
          node {
            id
            lotId
            ended
            publicMintId
            cost
            asset {
              title
              slug
              description {
                description
              }
              mainMedia {
                file {
                  url
                  contentType
                }
                gatsbyImageData
              }
              animationPreviewMedia {
                file {
                  url
                  contentType
                }
                title
              }

              artist {
                id
                name
                slug
              }
              tokenId
            }
          }
        }
      }
    }
  `)

  const auctionNFT = data?.allContentfulLot?.edges
  const buyNowNFT = data?.allContentfulPublicMintLot?.edges.sort((a, b) =>
    parseInt(a?.node?.lotId) > parseInt(b?.node?.lotId) ? 1 : -1
  )

  const [showAuctions, setShowAuctions] = useState(true)
  const [auctionFilter] = useQueryParamString(`auctionfilter`, "")
  const [buyNowFilter] = useQueryParamString(`buyNowfilter`, "")

  const auctionNftFilterd = useMemo(() => {
    const filterName = auctionFilter.split(" ")[0]
    const result = auctionFilter.split(" ").slice(1).join(" ")

    switch (filterName) {
      case "":
        return auctionNFT

      case "all":
        return auctionNFT

      case "Creator":
        return auctionNFT.filter(
          auction => auction?.node?.asset?.artist?.name === result
        )

      case "SMB":
        return auctionNFT.filter(
          auction => auction?.node?.asset?.smb?.name === result
        )

      default:
        return auctionNFT
    }
  }, [auctionFilter, auctionNFT])

  const buyNowNftFilterd = useMemo(() => {
    const filterName = buyNowFilter.split(" ")[0]
    const result = buyNowFilter.split(" ").slice(1).join(" ")

    switch (filterName) {
      case "":
        return buyNowNFT

      case "all":
        return buyNowNFT

      case "Creator":
        return buyNowNFT.filter(
          buyNow => buyNow?.node?.asset?.artist?.name === result
        )

      case "SMB":
        return buyNowNFT.filter(
          buyNow => buyNow?.node?.asset?.smb?.name === result
        )

      default:
        return buyNowNFT
    }
  }, [buyNowFilter, buyNowNFT])

  const auctions = useMemo(() => {
    return getAuctionsIds(auctionNFT)
  }, [auctionNFT])

  const publicMints = getPublicMintIds(buyNowNFT)

  const auctionRef = useRef<null | HTMLDivElement>(null)
  const buyNowRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      //TODO: Find better solution to this
      const href = window.location.href
      const filterApplied = href.split("?")
      if (filterApplied.length > 1) {
        if (filterApplied[1].toString().toLocaleLowerCase() === "auction=all") {
          auctionRef?.current?.scrollIntoView()
          setShowAuctions(true)
        } else if (
          filterApplied[1].toString().toLocaleLowerCase() === "buynow=all"
        ) {
          buyNowRef?.current?.scrollIntoView()
          setShowAuctions(false)
        }
      }
    }, 500)
  }, [auctionRef.current])

  return (
    <Page>
      <Seo title={"NFT Marketplace"} />

      <Container>
        <div className={styles.nftMarketplace}>
          <h1>NFT Marketplace</h1>

          <div className={styles.nftMarketplaceSwitcher}>
            <div className={styles.nftMarketplaceSwitcherInner}>
              <button
                onClick={() => setShowAuctions(true)}
                className={cn(styles.nftMarketplaceSwitch, {
                  [styles.nftMarketplaceActiveSwitch]: showAuctions === true,
                })}
              >
                Auction <span>{auctionNFT.length}</span>
              </button>
              <button
                onClick={() => setShowAuctions(false)}
                className={cn(styles.nftMarketplaceSwitch, {
                  [styles.nftMarketplaceActiveSwitch]: showAuctions === false,
                })}
              >
                Buy Now <span>{buyNowNFT.length}</span>
              </button>
            </div>
            <MobileCreatorFilter name={showAuctions ? "auction" : "buyNow"} />
          </div>

          <div className={styles.nftMobile}>
            <LoadableGalleryMonitor
              auctions={auctions}
              render={({ result }: any) => {
                const enrichedData = enricheLotWithAuction(
                  auctionNFT || [],
                  result?.auctions
                )

                const filteredEnrichedData = enrichedData.filter(data =>
                  auctionNftFilterd.find(nft => nft.node.id === data.id)
                )

                if (filteredEnrichedData.length <= 0 && showAuctions) {
                  return <NoResultText />
                } else {
                  return (
                    showAuctions && (
                      <div className={styles.nftGrid}>
                        {filteredEnrichedData?.map(data => (
                          <NftCard key={data.id} nft={data} nftType="auction" />
                        ))}
                      </div>
                    )
                  )
                }
              }}
            />

            <LoadableGalleryMonitorPublicMint
              publicMints={publicMints}
              render={({ result }: any) => {
                const enrichedData = enricheLotWithPublicMints(
                  buyNowNFT || [],
                  result?.publicMints
                )

                const filteredEnrichedData = enrichedData.filter(data =>
                  buyNowNftFilterd.find(nft => nft.node.id === data.id)
                )

                if (filteredEnrichedData.length <= 0 && !showAuctions) {
                  return <NoResultText />
                } else {
                  return (
                    <>
                      {!showAuctions && (
                        <div className={styles.nftGrid}>
                          {filteredEnrichedData?.map(nft => (
                            <NftCard key={data.id} nft={nft} nftType="buyNow" />
                          ))}
                        </div>
                      )}
                    </>
                  )
                }
              }}
            />
          </div>

          <div className={styles.nftDesktop}>
            <div ref={auctionRef} className={styles.nftFilterContainer}>
              <h2>Auction</h2>
              <CreatorFilter name="auction" />
            </div>

            <LoadableGalleryMonitor
              auctions={auctions}
              render={({ result }: any) => {
                const enrichedData = enricheLotWithAuction(
                  auctionNFT || [],
                  result?.auctions
                )

                const filteredEnrichedData = enrichedData.filter(data =>
                  auctionNftFilterd.find(nft => nft.node.id === data.id)
                )
                if (filteredEnrichedData.length <= 0) {
                  return (
                    <div className={styles.NFTGridNoResult}>
                      <NoResultText />
                    </div>
                  )
                } else {
                  return (
                    <div className={styles.nftAuctionGrid}>
                      {filteredEnrichedData?.map(data => (
                        <NftCard nft={data} nftType="auction" />
                      ))}
                    </div>
                  )
                }
              }}
            />

            <div ref={buyNowRef} className={styles.nftFilterContainer}>
              <h2 id="#buyNow">Buy Now</h2>
              <CreatorFilter name="buyNow" />
            </div>

            <LoadableGalleryMonitorPublicMint
              publicMints={publicMints}
              render={({ result }: any) => {
                const enrichedData = enricheLotWithPublicMints(
                  buyNowNFT || [],
                  result?.publicMints
                )

                const filteredEnrichedData = enrichedData.filter(data =>
                  buyNowNftFilterd.find(nft => nft.node.id === data.id)
                )

                //Sort order: 1. available 2. coming soon 3. sold out
                // const sorted = groupBy(filteredEnrichedData, (item: any) => {
                //   const isComingSoon =
                //     !item?.asset?.tokenId ||
                //     !item?.auction ||
                //     !item?.auction?.tokens?.find(obj => {
                //       return obj?.tokenId === item?.asset?.tokenId
                //     }) ||
                //     isFuture(new Date(item?.auction?.start * 1000))

                //   const isSold = !isComingSoon
                //     ? item?.auction?.sold?.indexOf(item?.asset?.tokenId) !== -1
                //     : false

                //   return isComingSoon
                //     ? "comingSoon"
                //     : isSold
                //     ? "sold"
                //     : "available"
                // })

                // const cards = concat(
                //   sorted.available,
                //   sorted.comingSoon,
                //   sorted.sold
                // ).filter(Boolean)

                if (filteredEnrichedData.length <= 0) {
                  return (
                    <div className={styles.NFTGridNoResult}>
                      <NoResultText />
                    </div>
                  )
                } else {
                  return (
                    <div className={styles.nftBuyNowGrid}>
                      {filteredEnrichedData?.map(nft => (
                        <NftCard nft={nft} nftType="buyNow" />
                      ))}
                    </div>
                  )
                }
              }}
            />
          </div>
        </div>
      </Container>
    </Page>
  )
}

export default NftMarketplace

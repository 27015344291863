import React from "react"

import cn from "classnames"

import * as styles from "./FooterAllRights.module.scss"

interface IFooterAllRights {
  hideOnDesktop?: boolean
  hideOnMobile?: boolean
}

const FooterAllRights = ({
  hideOnDesktop = false,
  hideOnMobile = false,
}: IFooterAllRights) => {
  return (
    <div
      className={cn(styles.footerAllRights, {
        [styles.footerAllRightsHideOnMobile]: hideOnMobile === true,
        [styles.footerAllRightsHideOnDesktop]: hideOnDesktop === true,
      })}
    >
      <ul>
        <li>©Copyright 2022</li>
        <li>
          <a
            href="https://metadyn.io/terms-conditions/"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>
        </li>
        <li>
          <a
            href="https://metadyn.io/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </li>
      </ul>
    </div>
  )
}

export default FooterAllRights

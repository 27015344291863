import React from "react"
import * as styles from "./NoResultText.module.scss"

export const NoResultText = () => {
  return (
    <div className={styles.NoResultTextContainer}>
      <h1>No results found</h1>
    </div>
  )
}

import React, { useEffect, useMemo, useRef, useState } from "react"
import { useSpring, animated } from "@react-spring/web"
import * as styles from "./MobileCreatorFilter.module.scss"
import { AiOutlineFilter, AiOutlineUp, AiOutlineDown } from "react-icons/ai"
import { useQueryParamString } from "react-use-query-param-string"
import { graphql, useStaticQuery } from "gatsby"

export const MobileCreatorFilter = ({ name }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulArtist {
        edges {
          node {
            name
            id
          }
        }
      }

      allContentfulHomepage {
        edges {
          node {
            smBs {
              __typename
              ... on ContentfulArtist {
                id
                name
                slug
                coverImage {
                  file {
                    url
                  }
                  title
                }
                description {
                  description
                }
              }
            }
          }
        }
      }
    }
  `)

  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLParagraphElement>(null)
  const buttonRef = useRef(null)
  const [animatedStyles, animate] = useSpring(() => ({ height: "0px" }), [])
  const [filter, setFilter] = useQueryParamString(`${name}filter`, "")

  const handleClickOutside = event => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    animate({
      height: (open ? ref?.current?.offsetHeight : 0) + "px",
    })
  }, [animate, ref, open])

  const smbs = useMemo(() => {
    const smbsData = data?.allContentfulSmb?.edges
    const homepageSmbs =
      data?.allContentfulHomepage?.edges?.[0]?.node?.smBs.map(smb => {
        return {
          node: {
            ...smb,
          },
        }
      })

    const filteredSmbs = smbsData?.filter(smb => {
      {
        if (smb.node.name !== null) {
          const match = homepageSmbs.find(
            homepageSmb => homepageSmb?.node?.name === smb.node.name
          )

          if (!match) {
            return smb
          }
        }
      }
    })

    const orderedSmbs = filteredSmbs?.concat(homepageSmbs)

    const uniqueSmbs = [
      ...new Map(orderedSmbs?.map(item => [item.node["id"], item])).values(),
    ]

    return uniqueSmbs
  }, [data])

  return (
    <div className={styles.mobileCreatorFilterContainer}>
      <div ref={buttonRef} className={styles.mobileCreatorFilterButton}>
        <button
          onClick={() => {
            setOpen(!open)
            animate({
              height: (!open ? ref?.current?.offsetHeight : 0) + "px",
            })
          }}
        >
          <div>
            <AiOutlineFilter />
            {open && <AiOutlineUp />}
            {!open && <AiOutlineDown />}
          </div>
        </button>
      </div>
      <animated.div
        style={animatedStyles}
        className={styles.mobileCreatorFilterList}
      >
        <div ref={ref}>
          <ul>
            <li className={filter === "all" ? styles.selected : styles.none}>
              <button
                onClick={() => {
                  setFilter("all")
                }}
              >
                All Creators
              </button>
            </li>

            <li>
              <ul>
                {data?.allContentfulArtist?.edges?.map(edge => (
                  <li
                    key={edge?.node?.name}
                    className={
                      edge?.node?.name ===
                      filter.replace("SMB", "").replace("Creator", "").trim()
                        ? styles.selected
                        : styles.none
                    }
                  >
                    <button
                      onClick={() => {
                        setFilter(`Creator ${edge?.node?.name}`)
                        setOpen(false)
                        animate({
                          height:
                            (open ? ref?.current?.offsetHeight : 0) + "px",
                        })
                      }}
                    >
                      {edge?.node?.name}
                    </button>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </animated.div>
    </div>
  )
}

// extracted by mini-css-extract-plugin
export var carousel = "Carousel-module--carousel--Wb5tY";
export var carouselDesktop = "Carousel-module--carouselDesktop--s-aj3";
export var carouselImgBtns = "Carousel-module--carouselImgBtns--um4hT";
export var embla = "Carousel-module--embla--iJU3s";
export var embla__button = "Carousel-module--embla__button---m+4U";
export var embla__buttonNext = "Carousel-module--embla__button--next--KLqpR";
export var embla__buttonPrev = "Carousel-module--embla__button--prev--wVJwi";
export var embla__button__svg = "Carousel-module--embla__button__svg--t-pEx";
export var embla__container = "Carousel-module--embla__container--4cfOj";
export var embla__dot = "Carousel-module--embla__dot--HMc-p";
export var embla__dots = "Carousel-module--embla__dots--9+8n0";
export var embla__slide = "Carousel-module--embla__slide--1Ca1Q";
export var embla__slide__inner = "Carousel-module--embla__slide__inner--vSd2B";
export var embla__slide__inner__img = "Carousel-module--embla__slide__inner__img--GLofZ";
export var embla__video = "Carousel-module--embla__video--ditWj";
export var embla__video__overlay = "Carousel-module--embla__video__overlay--JHwsc";
export var embla__viewport = "Carousel-module--embla__viewport--Kt7AL";
export var isDraggable = "Carousel-module--is-draggable--egcLk";
export var isDragging = "Carousel-module--is-dragging--MC-Qq";
export var isSelected = "Carousel-module--is-selected--Fvtea";
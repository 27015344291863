import {associateWalletWithUser} from "./auction";

export enum Action {
  BID = "bid",
  CONNECTWALLET = "connectwallet",
  LOGIN = "login",
}
export const track = (action: Action, data: any) => {
  window?.gtag?.("event", action, data)

  window?.analytics?.track(action, data)

  switch (action) {
    case Action.BID:
      break
    case Action.CONNECTWALLET:
      associateWalletWithUser(data)
      break

    case Action.LOGIN:
      window?.analytics?.identify(data.id, {
        email: data.email,
        name: data.name,
      })
      break

    default:
  }
}

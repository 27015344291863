import React from "react"
import cn from "classnames"

import * as styles from "./Container.module.scss"

const Container = ({ children, className }: any) => {
  return <div className={cn(styles.container, className)}>{children}</div>
}

export default Container

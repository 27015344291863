/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

import Header from "../Header/Header"

import { useCookies } from "react-cookie"

import { useRecoilState } from "recoil"
import { AuthState, isAuthenticatedState, userState } from "../../state/user"
import { Helmet } from "react-helmet"
import jwt_decode from "jwt-decode"

import * as styles from "./Layout.module.scss"
import { Action, track } from "../../utils/analytics"
import { ArtsyFooter } from "../Footer/ArtsyFooter"

const Layout = ({ children, withFooter = true, location }: any) => {
  const [, setUser] = useRecoilState(userState)
  const [, setAuthState] = useRecoilState(isAuthenticatedState)
  const [token, setToken] = useState<string | null>(null)
  const [cookies, _, removeCookie] = useCookies(["token"])
  useEffect(() => {
    if (window) {
      setToken(cookies?.token?.access_token)
    }
  }, [])

  useEffect(() => {
    auth()
  }, [token])

  const auth = async () => {
    if (token) {
      const decoded = jwt_decode(token)
      try {
        const res = await fetch(
          `https://api.artsy.net/api/user_details/${decoded?.sub}`,
          {
            headers: {
              "Content-Type": "application/json",
              "X-Access-Token": token,
            },
            method: "GET",
          }
        )
        const resJson = await res.json()

        track(Action.LOGIN, {
          id: resJson.id,
          email: resJson.email,
          name: resJson.name,
        })

        setUser(resJson)
        setAuthState(AuthState.authenticated)
      } catch (err) {
        setUser(null)
        setAuthState(AuthState.unauthenticated)
        removeCookie("token")
      }
    } else {
      setUser(null)
    }
  }

  return (
    <>
      <Helmet />

      <div className={styles.layout}>
        <Header location={location} />
        <main>{children}</main>
        <ArtsyFooter />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

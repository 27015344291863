import { setFluidGetter } from "@react-spring/shared"
import React from "react"
import { CreatorFilter } from "../../CreatorFilter/CreatorFilter"
import { MobileCreatorFilter } from "../../CreatorFilter/MobileCreatorFilter"
import * as styles from "./SectionHeader.module.scss"

interface SectionHeaderProps {
  subTitle: string
}

export const SectionHeader = ({ subTitle }: SectionHeaderProps) => {
  return (
    <div className={styles.sectionHeader}>
      <div className={styles.sectionHeaderSubTitle}>
        <h3>{subTitle}</h3>
      </div>
    </div>
  )
}

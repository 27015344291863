import React from "react"
import { Link } from "gatsby"

import * as styles from "./NavBar.module.scss"
import { ConnectWalletButton } from "../Header/ConnectWalletButton"

const NavBar = () => {
  return (
    <div className={styles.navbar}>
      <ul className={styles.navbarList}>
        <li>
          <Link to="/nft-marketplace">NFT Marketplace</Link>
        </li>
        <li>
          <Link to="/faq">FAQ</Link>
        </li>
      </ul>
      <ul className={styles.navbarList}>
        <>
          <li>
            <ConnectWalletButton />
          </li>
        </>
      </ul>
    </div>
  )
}

export default NavBar

import React from "react"

import { graphql, Link } from "gatsby"

import Container from "../../components/Container/Container"
import Creator from "../../components/Creator/Creator"
import { ScrollToRead } from "../../components/ScrollToRead/ScrollToRead"
import Page from "../../components/Page/Page"

import * as styles from "./Artist.module.scss"
import Seo from "../../components/seo"

const Artist = ({ data }: any) => {
  return (
    <Page>
      <Seo
        title={data?.contentfulArtist?.name}
        image={`https:${data?.contentfulArtist?.coverImage?.file?.url}`}
        description={data?.contentfulArtist?.description}
      />

      <div className={styles.smbHero}>
        <img
          className={styles.smbHeroBackgroundImg}
          src={data?.contentfulArtist?.coverImage?.file?.url}
          alt={data?.contentfulArtist?.coverImage?.title}
        />

        <h1>{data?.contentfulArtist?.name}</h1>

        <Link
          className={styles.smbViewLink}
          to={`/nft-marketplace/?auctionfilter=Creator%20${encodeURI(
            data?.contentfulArtist?.name
          )}&buyNowfilter=Creator%20${encodeURI(data?.contentfulArtist?.name)}`}
        >
          View NFTs
        </Link>

        <div className={styles.smbScroll}>
          <ScrollToRead />
        </div>
      </div>

      <Container>
        <Creator
          description={data?.contentfulArtist?.description.description}
          label="About our creator"
          name={data?.contentfulArtist?.name}
          profilePicture={data?.contentfulArtist?.profilePicture}
        />
      </Container>
    </Page>
  )
}

export default Artist

export const artistQuery = graphql`
  query ($artistId: String!) {
    contentfulArtist(contentful_id: { eq: $artistId }) {
      description {
        description
      }

      profilePicture {
        gatsbyImageData
        title
      }
      name
      coverImage {
        gatsbyImageData
        title
        file {
          url
        }
      }
    }
  }
`

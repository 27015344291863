import * as React from "react"

import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"
import * as style from "../scss/404.module.scss"
import image from "../images/404.png"

const NotFoundPage = () => {
  return (
    <Layout withFooter={false}>
      <Seo title="404: Not found" />
      <div className={style.content}>
        <div className={style.imageDiv}>
          <img alt="404" src={image}></img>
        </div>
        <div>
          <h1>Oh, no! Error</h1>
          <p>Page not found</p>
        </div>
        <div className={style.buttonsContainer}>
          <a href="/">Back to Home</a>
          <a href="#">Report a Bug</a>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

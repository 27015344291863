// extracted by mini-css-extract-plugin
export var alertContainer = "BuyButton-module--alertContainer--MgukQ";
export var auctionDetailsBidInput = "BuyButton-module--auctionDetailsBidInput--lPaRf";
export var auctionDetailsButton = "BuyButton-module--auctionDetailsButton--5UT1b";
export var bidDetails = "BuyButton-module--bidDetails--rrhdo";
export var buyButtonConnectModal = "BuyButton-module--buyButtonConnectModal--yo8z1";
export var buyButtonModal = "BuyButton-module--buyButtonModal--Vx7pA";
export var error = "BuyButton-module--error--a21FM";
export var howBiddingWorks = "BuyButton-module--howBiddingWorks--Y-zIF";
export var indicator = "BuyButton-module--indicator--t5-rK";
export var minimumBid = "BuyButton-module--minimumBid--hXqHD";
export var pulse = "BuyButton-module--pulse--axjfd";
export var success = "BuyButton-module--success--TG01g";
// extracted by mini-css-extract-plugin
export var nftCard = "NftCard-module--nftCard--qPjAn";
export var nftCardArtist = "NftCard-module--nftCardArtist--BK1s-";
export var nftCardBackgroundImg = "NftCard-module--nftCardBackgroundImg--jCk8F";
export var nftCardBody = "NftCard-module--nftCardBody--c7xRi";
export var nftCardCategory = "NftCard-module--nftCardCategory--p4rGN";
export var nftCardDescription = "NftCard-module--nftCardDescription--lAvcD";
export var nftCardForgroundImg = "NftCard-module--nftCardForgroundImg--LR1fe";
export var nftCardId = "NftCard-module--nftCardId--MMKsI";
export var nftCardLink = "NftCard-module--nftCardLink--x2yos";
export var nftCardPrice = "NftCard-module--nftCardPrice--akReu";
export var nftCardPriceLabel = "NftCard-module--nftCardPriceLabel---UHLA";
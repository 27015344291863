import * as React from "react"

import Seo from "../components/seo"
import { graphql, Link, useStaticQuery } from "gatsby"
import Hero from "../components/Hero/Hero"

import Page from "../components/Page/Page"
import { AuctionSection } from "../components/NFTMarketPlace/AuctionSection"
import { BuyNowSection } from "../components/NFTMarketPlace/BuyNowSection"
import { FAQComponent } from "../components/FAQ/FAQ"

import * as styles from "../scss/Index.module.scss"
import "../scss/index.scss"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLot(filter: { ended: { eq: false } }) {
        edges {
          node {
            id
            lotId
            ended
            auctionId
            asset {
              title
              slug
              mainMedia {
                file {
                  url
                  contentType
                }
                title
                gatsbyImageData
              }
              animationPreviewMedia {
                file {
                  url
                  contentType
                }
                title
              }
              artist {
                id
                name
                slug
              }
            }
          }
        }
      }

      allContentfulPublicMintLot(filter: { ended: { eq: false } }) {
        edges {
          node {
            id
            lotId
            ended
            publicMintId
            cost
            asset {
              title
              slug
              mainMedia {
                file {
                  url
                  contentType
                }
                title
                gatsbyImageData
              }
              animationPreviewMedia {
                file {
                  url
                  contentType
                }
                title
              }
              artist {
                id
                name
                slug
              }
              tokenId
            }
          }
        }
      }

      allContentfulFaqPage {
        edges {
          node {
            faq {
              id
              question
              answer {
                answer
              }
            }
          }
        }
      }
    }
  `)
  const auctionNFT = data?.allContentfulLot?.edges

  const buyNowNFT = data?.allContentfulPublicMintLot?.edges?.sort((a, b) =>
    parseInt(a?.node?.lotId) > parseInt(b?.node?.lotId) ? 1 : -1
  )

  return (
    <Page>
      <Seo title="Home" />
      <Hero />
      <h2 className={styles.indexSubTitle}>NFT Marketplace</h2>
      <div className={styles.indexViewMarketplaceButton}>
        <Link to="/nft-marketplace">View Marketplace</Link>
      </div>

      <AuctionSection data={auctionNFT} />
      <BuyNowSection data={buyNowNFT} />
      <FAQComponent
        showViewAll
        data={data?.allContentfulFaqPage?.edges?.[0]?.node?.faq?.slice(0, 3)}
        showOneImg={true}
      />
    </Page>
  )
}

export default IndexPage

import { useCallback, useMemo, useState } from "react"

import BN from "bignumber.js"
import { get } from "lodash"
import { useWallet } from "use-wallet"
import web3 from "web3"

import { useContract, executeMethod } from "@monegraph/contracts"

import organizationAuction from "../contracts/organization-auction"
import monegraphErc721V3 from "../contracts/monegraph-erc721-v3"
import monegraphErc1155V2 from "../contracts/monegraph-erc1155-v2"

import { AuctionStatus, AuctionType } from "@monegraph/graph"

export enum BuyButtonState {
  Biddable,
  Buyable,
  Settleable,
  Sold,
}

export default function useBuyButton(auction: any, nftType, data) {
  const wallet = useWallet()
  const [errorState, setErrorState] = useState<string | null>()

  const { isFinalized } = useMemo(() => {
    return {
      isFinalized:
        get(auction, "status", AuctionStatus.OPEN) === AuctionStatus.FINALIZED,
    }
  }, [auction])

  const buttonState = useMemo(() => {
    if (isFinalized) {
      return BuyButtonState.Sold
    } else if (get(auction, "hasEnded")) {
      return BuyButtonState.Settleable
    } else if (auction.type === AuctionType.BUYNOW) {
      return BuyButtonState.Buyable
    } else {
      return BuyButtonState.Biddable
    }
  }, [auction, isFinalized, wallet.status])

  const contract = useContract(organizationAuction, {
    address: auction.contract,
  })

  const erc721Contract = useContract(monegraphErc721V3, {
    address: auction?.collection?.id,
  })

  const erc1155Contract = useContract(monegraphErc1155V2, {
    address: auction?.collection?.id,
  })

  const onSettle = useCallback(async () => {
    try {
      if (!isFinalized && auction.hasEnded) {
        await executeMethod(contract.finalize, [auction.index], {
          from: wallet.account,
        })

        setErrorState(null)
      }
    } catch (e) {
      switch (e.code) {
        // user rejected the transaction
        case 4001:
          throw e

        // insufficient funds
        case -32000:
          setErrorState(
            "Please deposit ETH into your MetaMask wallet. There are currently insufficient funds to place your bid."
          )
          throw e

        default:
          throw e
      }
    }
  }, [
    isFinalized,
    auction.hasEnded,
    auction.index,
    contract?.address,
    wallet.account,
  ])

  const onBuy = useCallback(
    async bid => {
      if (
        process.env.GATSBY_ETHERNET_CHAIN_ID == wallet.chainId &&
        nftType === "auction"
      ) {
        const minimumBid = BN.isBigNumber(auction?.minimumBid)
          ? auction?.minimumBid
          : new BN(auction?.minimumBid)

        const bidInWei =
          typeof bid === "string" ? web3.utils.toWei(bid, "ether") : minimumBid

        try {
          await executeMethod(contract.bid, [auction.index], {
            from: wallet.account,
            value: minimumBid.gt(bidInWei) ? auction.minimumBid : bidInWei,
          })
          setErrorState(null)
        } catch (e) {
          switch (e.code) {
            // user rejected the transaction
            case 4001:
              throw e

            // insufficient funds
            case -32000:
              setErrorState(
                "Please deposit ETH into your metamask wallet, insufficient funds to complete transaction."
              )
              throw e

            default:
              throw e
          }
        }
      } else {
        alert("Please connect to the ethereum mainnet and try again")
      }
    },
    [
      contract,
      wallet.account,
      auction?.minimumBid,
      auction?.index,
      wallet.chainId,
    ]
  )

  const onBuyNow = useCallback(async () => {
    if (
      process.env.GATSBY_ETHERNET_CHAIN_ID == wallet.chainId &&
      nftType === "buyNow"
    ) {
      const token = auction?.tokens?.find(obj => {
        return obj?.tokenId === data.asset?.tokenId
      })

      try {
        const availability = await fetch(
          `${process.env.GATSBY_AWS_ENDPOINT}/check-availability`,
          {
            method: "POST",
            body: JSON.stringify({
              publicMintId: auction?.id,
              recipient: wallet?.account,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        )

        const availabilityParsed = await availability.json()

        if (!availabilityParsed.enabled) {
          return
        }

        const authorize = await fetch(
          `${process.env.GATSBY_AWS_ENDPOINT}/authorize`,
          {
            method: "POST",
            body: JSON.stringify({
              publicMintId: auction?.id,
              transaction: {
                tokenId: token?.tokenId,
                recipient: wallet?.account,
              },
            }),
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              Allow: "GET, OPTIONS, POST",
              "Access-Control-Allow-Methods": "GET, OPTIONS, POST",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Allow-Credentials": true,
            },
          }
        )

        const authorizeParsed = await authorize.json()

        if (auction?.collection?.type === "erc721" && erc721Contract) {
          await executeMethod(
            erc721Contract.methods[
              "mint((uint256,address,string,(string,string,string,string,string),bytes,uint256,(uint16,address)[]))"
            ],
            [
              {
                tokenId: authorizeParsed?.tokenId,
                to: wallet?.account,
                uri: authorizeParsed?.uri,
                attributes: {
                  language: authorizeParsed?.attributes?.language,
                  year: authorizeParsed?.attributes?.year,
                  artist: authorizeParsed?.attributes?.artist,
                  royalty: authorizeParsed?.attributes?.royalty,
                  title: authorizeParsed?.attributes?.title,
                },
                signature: authorizeParsed?.signature,
                expires: authorizeParsed?.expires,
                beneficiaries: authorizeParsed?.beneficiaries,
              },
            ],
            {
              from: wallet.account,
              value: authorizeParsed?.cost,
            }
          )
        } else if (auction?.collection?.type === "erc1155") {
          await executeMethod(
            erc1155Contract.methods[
              "mint((uint256,address,string,(string,string,string,string,string),bytes,uint256,(uint16,address)[]))"
            ],
            [
              {
                tokenId: authorizeParsed?.tokenId,
                to: wallet?.account,
                uri: authorizeParsed?.uri,
                attributes: {
                  language: authorizeParsed?.attributes?.language,
                  year: authorizeParsed?.attributes?.year,
                  artist: authorizeParsed?.attributes?.artist,
                  royalty: authorizeParsed?.attributes?.royalty,
                  title: authorizeParsed?.attributes?.title,
                },
                signature: authorizeParsed?.signature,
                expires: authorizeParsed?.expires,
                beneficiaries: authorizeParsed?.beneficiaries,
              },
            ],
            {
              from: wallet.account,
              value: authorizeParsed?.cost,
            }
          )
        }

        setErrorState(null)
      } catch (e) {
        switch (e?.code || e?.originalError?.code) {
          // user rejected the transaction
          case 4001:
            throw e

          // insufficient funds
          case -32000:
            setErrorState(
              "Please deposit ETH into your metamask wallet, insufficient funds to complete transaction."
            )
            throw e

          case 3:
            setErrorState("Signature validation failed")
            throw e

          default:
            setErrorState("Something went wrong please try again")
            throw e
        }
      }
    } else {
      alert("Please connect to the ethereum mainnet and try again")
    }
  }, [erc721Contract, erc1155Contract, wallet, auction, nftType])

  // @ts-ignore
  window.onBuy = onBuy

  const active = useMemo(() => wallet.status === "connected", [wallet.status])

  const onConnectMetamask = useCallback(async () => {
    try {
      const chainId = process.env.GATSBY_ETHERNET_CHAIN_ID as string
      //@ts-ignore
      if (chainId != window.ethereum.networkVersion) {
        //@ts-ignore
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${Number(chainId).toString(16)}` }],
        })

        await wallet.connect("injected")

        new Promise(resolve => setTimeout(resolve, 300))
        // await timeout(300)
      }

      // return true
    } catch (error) {
      // return false
    }

    wallet.connect("injected")
  }, [wallet])

  const onConnectWalletconnect = useCallback(
    () => (active ? null : wallet.connect("walletconnect")),
    [active, wallet]
  )

  const clearErrors = () => setErrorState(null)

  const minimumBid = useMemo(() => {
    return nftType === "auction"
      ? web3.utils.fromWei(auction.minimumBid.toString(), "ether")
      : null
  }, [auction?.minimumBid, nftType])

  return {
    buttonState,
    errorState,
    minimumBid,
    wallet,
    onSettle,
    onBuy,
    onBuyNow,
    clearErrors,
    onConnectMetamask,
    onConnectWalletconnect,
  }
}

import { Button } from "@artsy/palette"
import React, { useCallback, useMemo, useState } from "react"
import Modal from "../Modal/Modal"

import MetamaskLogo from "../../images/metamask.svg"
import WalletConnectLogo from "../../images/walletconnect.svg"

import * as styles from "./Header.module.scss"
import { useWallet } from "use-wallet"

export const ConnectWalletButton = () => {
  const [openConnectModal, setOpenConnectModal] = useState(false)

  const wallet = useWallet()

  const metamaskInstalled = useMemo(() => {
    return typeof window !== "undefined" && window.ethereum
  }, [])

  const onConnectMetamask = useCallback(async () => {
    try {
      const chainId = process.env.GATSBY_ETHERNET_CHAIN_ID as string
      //@ts-ignore
      if (chainId != window.ethereum.networkVersion) {
        //@ts-ignore
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${Number(chainId).toString(16)}` }],
        })

        await wallet.connect("injected")

        new Promise(resolve => setTimeout(resolve, 300))
      }
    } catch (error) {}

    wallet.connect("injected")
  }, [wallet])

  const onConnectWalletconnect = useCallback(
    () => wallet.connect("walletconnect"),
    [wallet]
  )

  useMemo(() => {
    if (wallet.status === "connected") {
      setOpenConnectModal(false)
    }

    if (wallet.connector === "walletconnect") {
      if (wallet.ethereum) {
        wallet.ethereum.on = null
      }
    }
  }, [wallet.status, wallet.connector, wallet.ethereum])

  const metaMaskButton = useMemo(() => {
    return metamaskInstalled
      ? [
          <button
            key="button"
            onClick={onConnectMetamask}
            className="flex justify-start text-left px-5 py-10 text-center items-center gap-3 hover:bg-slate-100 w-full"
          >
            <img src={MetamaskLogo} width="50px" />
            <span className="text-lg">Metamask</span>
          </button>,
          <hr key="spacer" />,
        ]
      : null
  }, [metamaskInstalled])

  return (
    <>
      {!wallet.isConnected() ? (
        <Button onClick={() => setOpenConnectModal(true)}>
          Connect Wallet
        </Button>
      ) : (
        <></>
      )}

      {openConnectModal && (
        <Modal
          mobileFullScreen={true}
          closeModal={() => setOpenConnectModal(false)}
        >
          <div className={styles.buyButtonConnectModal}>
            {metaMaskButton}
            <button
              onClick={onConnectWalletconnect}
              className="flex justify-start text-left px-5 py-10 text-center items-center gap-3 hover:bg-slate-100 w-full"
            >
              <img src={WalletConnectLogo} width="50px" />
              <span className="text-lg">Wallet Connect</span>
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}

/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useRef, useState } from "react"

import * as styles from "./Hero.module.scss"
import Container from "../Container/Container"
import { ScrollToRead } from "../ScrollToRead/ScrollToRead"
import { SMBCreatorCard } from "../SMB/SMBCreatorCad"
import { graphql, Link, useStaticQuery } from "gatsby"
import { MdOutlineMusicNote, MdOutlineMusicOff } from "react-icons/md"

const Hero = () => {
  const data = useStaticQuery(
    graphql`
      {
        allContentfulHomepage {
          edges {
            node {
              label
              title
              subtitle
              image {
                title
                file {
                  contentType
                  url
                }
              }
              id
              smBs {
                __typename
                ... on ContentfulArtist {
                  id
                  name
                  slug
                  coverImage {
                    file {
                      url
                    }
                    title
                  }
                  description {
                    description
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const [muted, setMuted] = useState(true)

  const homepageData = data?.allContentfulHomepage?.edges?.[0]?.node

  const videoRef = useRef<null | HTMLDivElement>(null)

  const handlePlayVideo = () => {
    setMuted(!muted)
    videoRef.current.muted = !videoRef.current.muted
  }

  return (
    <>
      <div className={styles.heroBackground}>
        {homepageData?.image?.file?.contentType?.includes("video") ? (
          <video
            src={homepageData?.image?.file?.url}
            width="100%"
            height="100%"
            playsInline
            autoPlay
            muted
            loop
            ref={videoRef}
          ></video>
        ) : (
          <img
            src={homepageData?.image?.file?.url}
            alt={homepageData?.image?.title}
          />
        )}
      </div>
      <Container>
        <div className={styles.hero}>
          <div>
            <div className={styles.heroInner}>
              <div>
                <h4>{homepageData?.label}</h4>
              </div>
              <div>
                <h1>{homepageData?.title}</h1>
              </div>
            </div>

            <div className={styles.heroDescription}>
              <div className={styles.heroDescriptionInner}>
                <p>{homepageData?.subtitle}</p>
              </div>
            </div>
            <div className={styles.herobuttonContainer}>
              <Link
                to="/nft-marketplace/?auction=all"
                className={styles.herobutton}
              >
                View Auction
              </Link>

              <Link
                to="/nft-marketplace/?buyNow=all"
                className={styles.herowhiteButton}
              >
                Buy NFT
              </Link>
            </div>
          </div>
        </div>
        <div>
          {homepageData?.image?.file?.contentType?.includes("video") && (
            <button
              onClick={() => handlePlayVideo()}
              className={styles.heroMuteButton}
            >
              {muted ? <MdOutlineMusicNote /> : <MdOutlineMusicOff />}
            </button>
          )}

          <ScrollToRead />
        </div>
        <div className={styles.herosmbContainer}>
          {homepageData?.smBs?.map(smb => (
            <SMBCreatorCard key={smb?.id} smb={smb} />
          ))}
        </div>

        <div className={styles.MobileCreatorSMBcontainer}>
          {homepageData?.smBs?.map(smb => (
            <SMBCreatorCard key={smb?.id} smb={smb} />
          ))}

          {homepageData?.image?.file?.contentType?.includes("video") && (
            <button
              onClick={() => handlePlayVideo()}
              className={styles.heroMuteButton}
            >
              {muted ? <MdOutlineMusicNote /> : <MdOutlineMusicOff />}
            </button>
          )}
          <ScrollToRead />
        </div>
      </Container>
    </>
  )
}

export default Hero

import React, { useEffect, useRef } from "react"
import FaqItem from "../FaqItem/FaqItem"
import * as style from "./FAQ.module.scss"
import { Link } from "gatsby"

export const FAQComponent = ({
  data,
  showViewAll = false,
  showOneImg = false,
}) => {
  const ref = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    ref?.current?.offsetHeight.toString() % 800
  }, [ref])

  return (
    <div ref={ref} className={style.faqContainer}>
      <div className={style.faqheaderTitle}>
        <div className={style.faqmobileH1}>
          <h1>FAQ</h1>
        </div>
        <div className={style.faqdesktopH1}>
          <h1>Frequently Asked Questions</h1>
        </div>
      </div>

      {data?.map((faq: any) => (
        <div className={style.faqitems} key={faq?.id}>
          <FaqItem question={faq?.question} answer={faq?.answer?.answer} />
        </div>
      ))}

      {showViewAll ? (
        <div className={style.viewAllFaqButton}>
          <Link to="/faq">View All</Link>
        </div>
      ) : null}
    </div>
  )
}

import React, { useMemo } from "react"
import Countdown from "../Countdown/Countdown"
import { isPast, format } from "date-fns"
import Skeleton from "react-loading-skeleton"

import * as styles from "./AuctionDetails.module.scss"

const AuctionDate = ({ auction, data, nftType }: any) => {
  const date = useMemo(() => {
    if (auction && data) {
      if (nftType === "auction") {
        const startingDate = new Date(auction?.dateStarted * 1000)
        const endingDate = new Date(auction?.dateEnding * 1000)

        const auctionHasStarted = isPast(startingDate)

        return (
          <div className={styles.auctionDetailsEndingInner}>
            <p>
              {auctionHasStarted ? "Auction ends in: " : "Auction begins in: "}
            </p>
            <Countdown
              classname={styles.auctionDetailsCountdown}
              deadline={auctionHasStarted ? endingDate : startingDate}
            />
          </div>
        )
      } else if (nftType === "buyNow") {
        const tokenId = data?.asset?.tokenId

        const startingDate = new Date(auction?.general?.start * 1000)

        const auctionHasStarted = isPast(startingDate)
        const staticDate = new Date("6/20/2022 12:00:00 PM")

        const tokenIdExistsInContract = auction?.tokens?.find(
          (token: any) => token.tokenId === tokenId
        )

        if (!tokenIdExistsInContract || !auctionHasStarted) {
          return (
            <div className={styles.buyNowDetailsEndingInner}>
              <p>Available for purchase on:</p>
              <span>{format(startingDate, "MMM Do YY")}</span>
            </div>
          )
        } else {
          return <></>
        }
      }
    }
  }, [auction, nftType, data])

  return (
    <div className={styles.auctionDetailsEnding}>
      {auction ? date : <Skeleton width="100px" />}
    </div>
  )
}

export default AuctionDate

import React from "react"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import ReactMarkdown from "react-markdown"

import * as styles from "./Creator.module.scss"

interface ICreator {
  profilePicture: any
  name: string
  description: string
  label: string
}

const Creator = ({ profilePicture, description, label }: ICreator) => {
  return (
    <div className={styles.creator}>
      <h2 className={styles.creatorHeaderMobile}>{label}</h2>
      <div className={styles.creatorImage}>
        <GatsbyImage image={getImage(profilePicture)} />
      </div>

      <div className={styles.creatorDescription}>
        <h2 className={styles.creatorHeaderDesktop}>{label}</h2>
        <ReactMarkdown>{description}</ReactMarkdown>
      </div>
    </div>
  )
}

export default Creator

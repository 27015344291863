import React, { useEffect, useMemo, useState } from "react"
import * as styles from "./AuctionSection.module.scss"
import { NFTCard } from "./NFTCard/NFTCard"
import { SectionHeader } from "./SectionHeader/SectionHeader"
import { graphql, useStaticQuery } from "gatsby"
import CreatorFilter from "../CreatorFilter/CreatorFilter"

import { useQueryParamString } from "react-use-query-param-string"
import { MobileCreatorFilter } from "../CreatorFilter/MobileCreatorFilter"

import loadable from "@loadable/component"
import { enricheLotWithAuction, getAuctionsIds } from "../../utils/auction"
import { NoResultText } from "../NoResultText/NoResultText"

const LoadableGalleryMonitor = loadable(
  () => import("../../components/GalleryMonitor/GalleryMonitor")
)

export const AuctionSection = ({ data }: any) => {
  const [filter, setFilter] = useQueryParamString(`auctionfilter`, "")
  const [hasData, setHasdata] = useState(true)

  const dataFilterd = useMemo(() => {
    const filterName = filter.split(" ")[0]
    const result = filter.split(" ").slice(1).join(" ")
    switch (filterName) {
      case "":
        return data

      case "all":
        return data

      case "Creator":
        return data.filter(
          auction => auction?.node?.asset?.artist?.name === result
        )

      case "SMB":
        return data.filter(
          auction => auction?.node?.asset?.smb?.name === result
        )

      default:
        return data
    }
  }, [data, filter])

  const auctions = useMemo(() => {
    return getAuctionsIds(data)
  }, [data])

  return (
    <div className={styles.AuctionSectionauctionSection}>
      <div className={styles.AuctionSectionallContainer}>
        <div className={styles.auctionSectionHeader}>
          <SectionHeader subTitle="Auction" />
          <CreatorFilter name="auction" />
          <MobileCreatorFilter name="auction" />
        </div>

        <div
          className={styles.AuctionSectioncardsContainer}
          style={{ display: hasData ? "grid" : "flex" }}
        >
          <LoadableGalleryMonitor
            auctions={auctions}
            render={({ result }: any) => {
              const enrichedData = enricheLotWithAuction(
                data || [],
                result?.auctions
              )
              const filteredEnrichedData = enrichedData.filter(data =>
                dataFilterd.find(nft => nft.node.id === data.id)
              )

              if (filteredEnrichedData.length <= 0) {
                setHasdata(false)
                return <NoResultText />
              } else {
                setHasdata(true)
                return (
                  <>
                    {filteredEnrichedData?.map(edge => (
                      <NFTCard
                        key={edge?.id}
                        slug={edge?.asset?.slug}
                        lotId={edge?.lotId}
                        imageURL={edge?.asset?.mainMedia}
                        title={"#" + edge?.lotId}
                        price={edge?.auction?.currentBidInETH}
                        nftType="auction"
                        animationPreviewMedia={
                          edge?.asset?.animationPreviewMedia
                        }
                      />
                    ))}
                  </>
                )
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

import { Link } from "gatsby"
import React from "react"
import * as style from "./SMBCreatorCard.module.scss"
import { get, has } from "lodash"

interface SMBCreatorCardProps {
  smb: any
}

export const SMBCreatorCard = ({ smb }: SMBCreatorCardProps) => {
  const smbTitle =
    smb?.__typename === "ContentfulSmb"
      ? smb?.title?.split(" x ")
      : smb?.name?.split(" x ")

  return (
    <Link
      to={`${smb?.__typename === "ContentfulSmb" ? "smb" : "artist"}/${
        smb?.slug
      }`}
    >
      <div
        style={{ backgroundImage: `url(${smb?.coverImage?.file?.url})` }}
        className={style.SMBCreatorCardcontainer}
      >
        <div className={style.SMBCreatorCardsecondContainer}>
          <div className={style.SMBCreatorCardcreatorName}>
            <div>
              <h4>{get(smbTitle, "[0]")}</h4>
              {has(smbTitle, "[1]") && (
                <>
                  <h4>x</h4>
                  <h4>{smbTitle[1]}</h4>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
